import { supabase } from '../authentication/supabaseClient';

export const signInWithGoogle = async () => {
  try {
    // Initiate the Google sign-in with Supabase
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
    });

    if (error) throw new Error(error.message);

    // data.session contains user info and token
    const { session } = data;

    if (session) {
      // Pass the session token and user details to your backend
      const userInfo = await sendToBackend(session);
      return userInfo;  // Return user info to the component
    }
  } catch (error) {
    console.error("Error during Google sign-in:", error);
    throw error;
  }
};

// Function to send session data to backend and onboard user
const sendToBackend = async (session) => {
  try {
    const response = await fetch('/api/auth/google-signin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${session.access_token}` // Use the session token for validation
      },
      body: JSON.stringify({
        email: session.user.email,
        firstName: session.user.user_metadata.full_name.split(' ')[0],
        lastName: session.user.user_metadata.full_name.split(' ')[1],
        avatar_url: session.user.user_metadata.avatar_url,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to onboard user');
    }

    const userInfo = await response.json();
    return userInfo;
  } catch (error) {
    console.error("Error sending user data to backend:", error);
    throw error;
  }
};
