import React, { useState, useEffect } from 'react';
import '../styles/SubscribeSection.css'; // Ensure you create this CSS file
import ReactGA from 'react-ga4';

const SubscribeSection = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked Email Subscribe Button',
      label: 'Email Subscribe Section',
    });
  }, []);
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubscription = (e) => {
    e.preventDefault();
    // Here, you can send the email to your backend or service handling subscriptions
    console.log('Subscribed with email:', email);
    setSubmitted(true);
    // You can also call your API to add the user to the newsletter
  };

  return (
    <div className="subscribe-section">
      <p>Get the latest lessons, articles and updates straight to your inbox.</p>
      
      {!submitted ? (
        <form onSubmit={handleSubscription} className="subscribe-form">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" className="subscribe-button">Subscribe</button>
        </form>
      ) : (
        <p className="thank-you-message">Thank you for subscribing! You will receive updates soon.</p>
      )}
    </div>
  );
};

export default SubscribeSection;
