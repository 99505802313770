import React, { useEffect } from 'react';
import '../styles/AboutMe.css'; // Make sure to include your CSS file
import { trackOutboundLinkClick } from '../components/analytics';
import ReactGA from 'react-ga4';

const AboutMe = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked AboutMe Page Button',
      label: 'AboutMe Page',
    });
  }, []);
  return (
    <div className="about-me-section">
      <div className="about-me-content">
      <div className="about-me-image">
        <img src="/images/bhuvnesh.avif" alt="About Me" />
      </div>
        <h1>Bhuvnesh Arya</h1>
        <p1><b>Architect, Technologist, and Mentor</b></p1>
        <p>
          I am a software architect and engineering leader with a passion for system design, scalable architectures, and cutting-edge engineering practices.
        </p>
        <p>
        I’ve been in the technology industry since 2010, and I have experience in the architecture and delivery of monolithic architectures, event-driven architectures, and domain-driven microservices architectures in a variety of platforms and languages. 
        </p>
        <p>
        I’ve published numerous posts and articles on areas of microservices, software architecture, and system design across my socials and I started this website (CodeAndBeyond.in) in August 2024 to help freshers and developers improve their skills and gain valuable insights into their work using AI-powered technology.
        </p>
        <p>I share my deep dives into engineering and my learnings across my social channels.</p>
        <p>Follow me on</p>
        
        <div class="social-links-aboutme">
          <a href="https://www.linkedin.com/in/bhuvnesh-arya/" target="_blank" class="social-link linkedin" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://www.linkedin.com/in/bhuvnesh-arya/')}>
            <img src="/images/linkedin.png" alt="LinkedIn" />
          </a>
          <a href="https://www.youtube.com/@bhuvnesharya10/" target="_blank" class="social-link youtube" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://www.youtube.com/@bhuvnesharya10/')}>
            <img src="/images/youtube.png" alt="Youtube" />
          </a>
          <a href="https://x.com/bhuvnesharya_in/" target="_blank" class="social-link twitter" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://x.com/bhuvnesharya_in/')}>
            <img src="/images/twitter-x.png" alt="Twitter" />
          </a> 
          <a href="https://github.com/bhuvnesharya/" target="_blank" class="social-link github" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://github.com/bhuvnesharya/')}>
            <img src="/images/github.png" alt="GitHub" />
          </a>
        </div>

      </div>
      
    </div>
  );
};

export default AboutMe;
