import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import BlogsPage from './pages/BlogsPage';
import AboutMePage from './pages/AboutMePage';
import ContactMePage from './pages/ContactMePage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import LessonsPage from './pages/LessonsPage';
import Lesson1 from './lessons/Lesson1';
import Lesson2 from './lessons/Lesson2';

const App = () => (
  <Router>
    <Header />
    <Routes>
      {/* Serve the homepage at the root path */}
      <Route path="/" element={<HomePage />} />
      {/* Redirect /home to the root path */}
      <Route path="/home" element={<Navigate to="/" />} />
      <Route path="/blogs" element={<BlogsPage />} />
      <Route path="/about-me" element={<AboutMePage />} />
      <Route path="/contact-me" element={<ContactMePage />} />
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/sign-up" element={<SignUpPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
      <Route path="/lessons" element={<LessonsPage />} />
      <Route path="/lessons/lesson1" element={<Lesson1 />} />
      <Route path="/lessons/lesson2" element={<Lesson2 />} />
      {/* Add more lesson routes as needed */}
    </Routes>
    <Footer />
  </Router>
);

export default App;