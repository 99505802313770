import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Added Link for navigation
import axios from 'axios';
import '../styles/SignInPage.css';
import ReactGA from 'react-ga4';
import { UserContext } from '../contexts/UserContext'; // Import UserContext
import { signInWithGoogle } from '../api/auth'; // Custom API for Google sign-in

const SignInPage = () => {
  
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [staySignedIn, setStaySignedIn] = useState(false); // State for "Stay signed in"
  const [apiError, setApiError] = useState('');  // For API errors
  const { setUser, setIsLoggedIn } = useContext(UserContext); // Get loginUser from context
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked Sign In Button',
      label: 'SignIn Page',
    });

    // Check if user is already logged in from previous session (JWT in localStorage)
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      // Validate token with backend and get user data
      fetch(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/auth/validate-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.user) {
            setUser(data.user);
            setIsLoggedIn(true);
            navigate("/blogs");
          }
        })
        .catch((error) => {
          console.error("Error validating session: ", error);
        });
    }
  }, [navigate, setUser, setIsLoggedIn]);

  const handleGoogleSignIn = async () => {
    try {
      const response = await signInWithGoogle(); // Custom function to handle Google sign-in

      // Store token and update context
      localStorage.setItem('token', response.token);
      setUser(response.user);
      setIsLoggedIn(true);

      navigate("/blogs"); // Redirect to /blogs after successful sign-in
    } catch (error) {
      console.error("Error during Google Sign-In: ", error);
    }
  };

  console.log(handleGoogleSignIn);


  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSignIn = async (formData) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/auth/signin`, formData);
        localStorage.setItem('token', response.data.token);  // Save token in localStorage
        setUser(response.data);  // Update context with user data
        setIsLoggedIn(true);
        navigate('/lessons');  // Redirect to lessons page
    } catch (error) {
        console.error("Sign-in failed. Please check your credentials and try again.", error);
        setApiError('Sign-in failed. ' + (error.response?.data?.message || 'Please check your credentials and try again.'));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();  // Prevent the form's default action (which would send a GET request)
    const formData = {
        email: event.target.email.value,
        password: event.target.password.value
    };
    handleSignIn(formData);  // Call your handleSignIn function
  };
  
  // Handle Sign Out
  const handleSignOut = () => {
    setIsLoggedIn(false);
    setUser(null);
    localStorage.removeItem('user');
    navigate('/signin');
  };

  console.log(handleSignOut);

  return (
    <div className="sign-in-container">
        <h2>Sign In</h2>
        {apiError && <p className="error">{apiError}</p>} {/* Display API error if any */}
        <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email Address"
              required
            />
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
              required
            />

          {/* Stay Signed In & Forgot Password */}
          <div className="form-footer">
            <div className="stay-signed-in">
              <input
                type="checkbox"
                checked={staySignedIn}
                onChange={() => setStaySignedIn(!staySignedIn)}
              />
              <label>Stay signed in</label>
            </div>
            <a href="/reset-password" className="forgot-password-link">Forgot Password?</a>
          </div>

          <button type="submit" className="email-sign-in-button">Sign in with Email</button>
        </form>
        <a href="/sign-up">Create a new account</a>
    </div>
  );
};

export default SignInPage;
