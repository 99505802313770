import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/LessonsPage.css';
import SubscribeSection from '../sections/SubscribeSection';
import ReactGA from 'react-ga4';

const LessonsPage = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Clicked Lessons Button',
      label: 'Lessons Page',
    });
  }, []);
  return (
    <div className="lessons-container">
      <div className="lessons-content">
        <h1>Software Engineering Almanac</h1>
        <p><b style={{ fontStyle: 'italic' }}>Software Engineering Almanac with Bhuvnesh Arya</b> is a free resource dedicated to delivering in-depth lessons on a wide range of software engineering topics. Each lesson covers essential aspects of coding, system design, software architecture, best practices, and the evolving culture of the software industry. These lessons are crafted to provide valuable insights, techniques, and practical advice to help you grow in your software engineering journey. New lessons will be regularly posted, offering a continuous source of learning and inspiration.</p>
        <ul className="lessons-list">
          <li><Link to="/lessons/lesson1">Lesson 1: Microservices Design Principles</Link></li>
          {/* Add more lessons as needed */}
        </ul>
      </div>
      <SubscribeSection />
    </div>
  );
};

export default LessonsPage;
