import React, { useEffect } from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';
import { trackOutboundLinkClick } from './analytics';
import '../styles/Footer.css'; 
import ReactGA from 'react-ga4';

const Footer = () => {
  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Footer Section',
      label: 'Footer Section',
    });
  }, []);
  return (
    <FooterContainer>
      <FooterSections>
        <LogoSocials>
          <a href="/" className="logo">
            <img src="/images/logo-icon-footer.avif" alt="Logo" className="logo-image" />
          </a>
          <p>Elevating Code with AI-Powered Insights</p>
          <SocialLinks>
            <a href="https://www.linkedin.com/in/bhuvnesh-arya/" target="_blank" className="social-link linkedin" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://www.linkedin.com/in/bhuvnesh-arya/')}>
              <img src="/images/linkedin.png" alt="LinkedIn" />
            </a>
            <a href="https://www.youtube.com/@bhuvnesharya10/" target="_blank" className="social-link youtube" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://www.youtube.com/@bhuvnesharya10/')}>
                <img src="/images/youtube.png" alt="Youtube" />
            </a>
            <a href="https://x.com/bhuvnesharya_in/" target="_blank" className="social-link twitter" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://x.com/bhuvnesharya_in/')}>
              <img src="/images/twitter-x.png" alt="Twitter" />
            </a> 
            <a href="https://github.com/bhuvnesharya/" target="_blank" className="social-link github" rel="noopener noreferrer" onClick={() => trackOutboundLinkClick('https://github.com/bhuvnesharya/')}>
              <img src="/images/github.png" alt="GitHub" />
            </a>
          </SocialLinks>
        </LogoSocials>
        <WritingsLearnings>
          <p>Writings and Learnings</p>
          <a href="/lessons">Lessons</a>
          <a href="/blogs">Blogs</a>
        </WritingsLearnings>
        <LegalContact>
          <p>Legal and Contact</p>
          <a href="/about-me">About Me</a>
          <a href="/contact-me">Contact Me</a>
          <a href="/privacy-policy.html">Privacy Policy</a>
        </LegalContact>
      </FooterSections>
      <hr className="separator-line" />
      <FooterText>&copy; 2024, CodeAndBeyond.</FooterText>
    </FooterContainer> 
  );
};

const FooterContainer = styled.footer`
  background-color: ${theme.colors.secondaryBackground};
  color: ${theme.colors.text};
  text-align: center;
  padding: 20px;
  margin-top: 30px;
`;

const FooterText = styled.p`
  margin: 0;
  font-family: ${theme.fonts.secondary};
  font-size: ${theme.typography.bodySmall.fontSize};
  font-weight: ${theme.typography.bodySmall.fontWeight};
  line-height: ${theme.typography.bodySmall.lineHeight};
`;

const FooterSections = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;

  div {
    margin: 0 20px;
  }
`;

const LogoSocials = styled.div`
  p {
    margin-bottom: 10px;
    margin-right: 35px;
    margin-top: 5px
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.typography.bodySmall.fontSize};
    font-weight: ${theme.typography.bodySmall.fontWeight};
    line-height: ${theme.typography.bodySmall.lineHeight};
  }
  
  a {
    display: block;
    color: ${theme.colors.secondaryText};
    text-decoration: none;
    margin-bottom: 5px;
    text-align: left;
    &:hover {
      text-decoration: underline;
    }      
  }
`;

const WritingsLearnings = styled.div`
  p {
    margin-bottom: 10px;
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.typography.body.fontSize};
    font-weight: ${theme.typography.body.fontWeight};
    line-height: ${theme.typography.body.lineHeight};
  }
  
  a {
    display: block;
    color: ${theme.colors.text};
    text-decoration: none;
    margin-bottom: 5px;
    text-align: left;
    &:hover {
      text-decoration: underline;
      color: #FFBB00;
    }
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.typography.bodySmall.fontSize};
    font-weight: ${theme.typography.bodySmall.fontWeight};
    line-height: ${theme.typography.bodySmall.lineHeight};
  }
`;

const LegalContact = styled.div`
  p {
    margin-bottom: 10px;
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.typography.body.fontSize};
    font-weight: ${theme.typography.body.fontWeight};
    line-height: ${theme.typography.body.lineHeight};
  }
  
  a {
    display: block;
    color: ${theme.colors.text};
    text-decoration: none;
    margin-bottom: 5px;
    text-align: left;
    &:hover {
      text-decoration: underline;
      color: #FFBB00;
    }
    font-family: ${theme.fonts.secondary};
    font-size: ${theme.typography.bodySmall.fontSize};
    font-weight: ${theme.typography.bodySmall.fontWeight};
    line-height: ${theme.typography.bodySmall.lineHeight};
  }
`;

const SocialLinks = styled.div`
  display: table-cell;
  flex-direction: row;
  align-items: flex-start; /* Align links to the start (left) */
  gap: 10px; /* Adjust gap between icons */
  margin: 0 auto; /* Center the box horizontally */
  color: #635A5A;

  a {
    margin: 0 5px;
    display: inline-block;
    
    img {
      width: 20px; /* Size of icons */
      height: 20px;
      transition: transform 0.3s ease; /* Smooth animation on hover */
      margin-right: 8px;
      margin-left: 8px;
      margin-top: 5px;
      margin-bottom: 3px;
    }

    &:hover {
      opacity: 0.8;
    }
  }
`;

export default Footer;
