import React, { useEffect } from 'react';
import IntroductionSection from '../sections/IntroductionSection'; // Adjust the path as needed
import '../styles/HomePage.css'; // Make sure to create a corresponding CSS file
import ReactGA from 'react-ga4';
import LessonsSection from '../sections/LessonsSection';
import BlogsSection from '../sections/BlogsSection';

const HomePage = () => {
    useEffect(() => {
      ReactGA.event({
        category: 'User Interaction',
        action: 'Clicked HomePage Button',
        label: 'Home Page',
      });
    }, []);
    return (
      <div className="homepage">
        <div className="hero-section">
          <div className="hero-content">
            
          </div>
        </div>
        <IntroductionSection />
        <LessonsSection />
        <BlogsSection />
      </div>
    );
  }

export default HomePage;
