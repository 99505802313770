// src/components/Header.js

import React, { useState, useEffect, useContext  } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import theme from '../styles/theme';
import '../styles/Header.css'; // Make sure to create a corresponding CSS file
import ReactGA from 'react-ga4';
import { UserContext } from '../contexts/UserContext'; // Import UserContext


const Header = () => {
  const { user, setUser, isLoggedIn, setIsLoggedIn } = useContext(UserContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);  // Toggle the menu state
  };

  useEffect(() => {
    ReactGA.event({
      category: 'User Interaction',
      action: 'Visit Header Section',
      label: 'Header Section',
    });

    // Check session on mount
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      fetch(`${process.env.REACT_APP_USER_SERVICE_API_URL}/api/auth/validate-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${storedToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if (data.user) {
            setUser(data.user);  // Update context with user data
            setIsLoggedIn(true);
          } else {
            localStorage.removeItem('token');
            setUser(null);
            setIsLoggedIn(false);
          }
        })
        .catch((error) => {
          console.error("Error validating session: ", error);
          localStorage.removeItem('token');
          setUser(null);
          setIsLoggedIn(false);
        });
    }
  }, [setUser, setIsLoggedIn]);

  const handleSignOut = () => {
    localStorage.removeItem('token');
    setUser(null);
    setIsLoggedIn(false);
    navigate('/sign-in');
  };

  return (
    <HeaderContainer>
      <a href="/" className="logo">
        <img src="/images/logo.avif" alt="Logo" className="logo-image" />
      </a>

      {/* Menu Toggle Button for Mobile */}
      <div className="menu-toggle" onClick={toggleMenu}>
        <span className="menu-text">Menu</span>
        <img src="/images/menu-icon.png" alt="Menu Button" className="menu-icon" />
      </div>

      <div className={`nav-container ${isMenuOpen ? 'open' : ''}`}>
        {/* Menu controlled by isMenuOpen state */}
        <Nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
          <NavItem href="/">Home</NavItem>
          <NavItem href="/lessons">Lessons</NavItem>
          <NavItem href="/blogs">Blogs</NavItem>
          <NavItem href="/about-me">About Me</NavItem>
          <DisplayItem>|</DisplayItem>
          {isLoggedIn ? (
            <div className="user-profile-dropdown">
              <div className="user-initials">
                {user.firstName[0]}{user.lastName[0]}
              </div>
              <div className="dropdown-content">
                <NavItem href="/profile">See Profile</NavItem>
                <button onClick={handleSignOut}>Sign Out</button>
              </div>
            </div>
          ) : (
            <NavItem href="/sign-in">Sign In</NavItem>
          )}
        </Nav>
      </div>

    </HeaderContainer>
  );
};



const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: ${theme.colors.primaryBackground};
  height: 60px; /* Adjust this value to set the desired height */
  padding-top: 10px; /* Adjust top padding */
  padding-bottom: 10px; /* Adjust bottom padding */
  margin: 0 auto; /* Center the container */
 
`;


const Nav = styled.nav`
  display: flex;
`;

const NavItem = styled.a`
  color: ${theme.colors.text};
  margin-left: 20px;
  font-family: ${theme.fonts.secondary};
  font-size: ${theme.typography.body.fontSize};
  text-transform: uppercase;
  font-weight: ${theme.typography.body.fontWeight};
  line-height: ${theme.typography.body.lineHeight};

  &:hover {
    color: ${theme.colors.accent};
  }
`;

const DisplayItem = styled.a`
  color: ${theme.colors.text};
  margin-left: 20px;
  font-family: ${theme.fonts.secondary};
  font-size: ${theme.typography.body.fontSize};
  text-transform: uppercase;
  font-weight: ${theme.typography.body.fontWeight};
  line-height: ${theme.typography.body.lineHeight};
`;

export default Header;
